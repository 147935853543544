<template>
<div class="database">
    <v-container fluid>
<v-simple-table >
    <template v-slot:default>
    <thead>
        <tr>
        <th class="text-left">Index</th>
        <th class="text-left">First</th>
        <th class="text-left">Last</th>
        <th class="text-left">Email</th>
        <th class="text-left">Phone</th>
        <th class="text-left">Address</th>
        <th class="text-left">Details</th>

        </tr>
    </thead>
    <tbody>
             <tr  v-for="(item, index) in items" :key="item.id">
        
       <td>{{ index }}</td>
        <td>{{ item.first }}</td>
        <td class="text-xs-right">{{ item.last }}</td>
        <td class="text-xs-right">{{ item.phone }}</td>
        <td class="text-xs-right">{{ item.email }}</td>
        <td class="text-xs-right">{{ item.address }}</td>
        <td class="text-xs-right">{{ item.details }}</td>
        <td class="text-xs-right">{{ item.time }}</td>


        </tr>
    </tbody>
    </template>
</v-simple-table>
</v-container>
</div> 
</template>





<script>
import { collection, getDocs, getFirestore } from "firebase/firestore";
export default {
name: "database",
data() {
    return {
        items: [],
    };
},
mounted(){
this.reader()
},
methods: {
async reader() {
const db = getFirestore();
const items = [];
const querySnapshot = await getDocs(collection(db, "user"));
querySnapshot.forEach((doc) => {
items.push(doc.data());
});
this.items = items;
},
test(){console.log('test')}
},

};

</script>   

<style scoped>
td {
    max-width: 150px;
}
</style>